import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { ProgressBarBasic, ProgressBarSmall } from "../../examples/components/ProgressBar";
export const query = graphql`
  query ProgressBarQuery {
    ProgressBar: componentMetadata(displayName: { eq: "ProgressBar" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Progress components are built with <code>&lt;ProgressBar&gt;</code> and prop{" "}
  <code>now</code> can be set to a value to indicate the progress so far.
  Attributes <code>role</code> and <code>aria</code> are already set to make it
  accessible.
    </Overview>
    <CodeBlock title="Basic" code={ProgressBarBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Small Variant" code={ProgressBarSmall} mdxType="CodeBlock"></CodeBlock>
    <LinkedHeading h="2" id="progess-api" className="h1" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.ProgressBar} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      